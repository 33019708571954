import BasePlugin from '../BasePlugin'

export default class FillRequiredDoc extends BasePlugin {
  async execute () {
    let model = this.context.getModel()
    let values = {
      recordId: model.id
    }

    this.send(values)
  }
}
